import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { copyMail, scheduledToDraft } from "../../api";
import { FormatDate } from "../../utils";
import { selectedContext } from "../../context";
import CustomDialog from "../CustomDialog";
import { Checkbox, Divider, FormControlLabel } from "@mui/material";

export function MailListItem(props) {
  const pathname = useLocation().pathname;
  const status = pathname.replace("/mail/", "");
  const { selectedItems, setSelectedItemsState } = useContext(selectedContext);

  const checkboxHandler = (e) => {
    let isSelected = e.target.checked;
    let value = parseInt(e.target.value);
    setSelectedItemsState(
      isSelected,
      [...selectedItems, value],
      selectedItems.filter((id) => id !== value)
    );
  };

  return (
    <li key={props.edm_id} className="mail-list-item">
      <FormControlLabel
        sx={{
          m: 0,
          px: 3,
          py: 2,
          width: "100%",
          "& .MuiCheckbox-root": { p: 0, mr: 2 },
          "& .MuiFormControlLabel-label": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          },
        }}
        control={
          <Checkbox
            size="small"
            value={props.edm_id}
            checked={selectedItems.includes(props.edm_id)}
            onChange={checkboxHandler}
          />
        }
        label={
          <>
            <Link
              className="flex flex-1"
              to={
                status === "draft"
                  ? `/mail/newmail?${props.edm_id}`
                  : `/mail/${props.edm_id}/effectiveness`
              }
              state={status !== "draft" && props.title}
            >
              <div className="flex-1">
                <p className="mb-2">{props.title}</p>
                <p className="text-[var(--color-neutral-400)] mb-2 text-base leading-none">
                  <i className="ri-draft-line text-base leading-none mr-[9px]"></i>
                  備註：
                  {props.remark}
                </p>
                <p className="text-[var(--color-neutral-400)] text-base leading-none">
                  <i className="ri-time-line text-base leading-none mr-[9px]"></i>
                  {status === "sended"
                    ? "完成日期："
                    : status === "scheduled"
                    ? "寄送日期："
                    : "更新日期："}
                  {props.send_time
                    ? FormatDate(props.send_time)
                    : FormatDate(props.update_date)}
                </p>
              </div>
              {status === "sended" && (
                <div className="flex-1 flex items-center gap-4">
                  <div>
                    <p>{props.arrived_percent || 0}%</p>
                    <p>到達率</p>
                  </div>
                  <Divider
                    sx={{ borderColor: "var(--color-primary-4)" }}
                    orientation="vertical"
                    variant="middle"
                    flexItem
                  />
                  <div>
                    <p>{props.opened_percent || 0}%</p>
                    <p>開信率</p>
                  </div>
                  <Divider
                    sx={{ borderColor: "var(--color-primary-4)" }}
                    orientation="vertical"
                    variant="middle"
                    flexItem
                  />
                  <div>
                    <p>{props.clicked_percent || 0}%</p>
                    <p>點擊率</p>
                  </div>
                </div>
              )}
            </Link>
            <div>
              {status === "schedule" && (
                <CustomDialog
                  className="svg-btn third-btn !mr-6"
                  variant="contained"
                  buttonTitle={
                    <>
                      <i className="ri-stop-circle-line"></i>停止
                    </>
                  }
                  dialogTitle={"確定要停止排程嗎？"}
                  dialogContent={"停止後，信件將移至草稿中"}
                  confirmFuncion={() => scheduledToDraft(props.edm_id)}
                  reload={true}
                />
              )}
              <CustomDialog
                className="svg-btn third-btn !mr-6"
                variant="contained"
                buttonTitle={
                  <>
                    <i className="ri-draft-line"></i>備註
                  </>
                }
                dialogTitle={"新增備註"}
                inputProps={{
                  url: "/api/mail/management/update/remark",
                  method: "patch",
                  props: { edm_id: props.edm_id, remark: props.remark },
                }}
                reload={true}
              />
              <CustomDialog
                className="svg-btn third-btn"
                variant="contained"
                buttonTitle={
                  <>
                    <i className="ri-file-copy-line text-2xl"></i>複製
                  </>
                }
                dialogTitle={"複製成功"}
                dialogContent={"請至草稿中編輯信件"}
                copyFuncion={() => copyMail(props.edm_id)}
                reload={true}
              />
            </div>
          </>
        }
      />
    </li>
  );
}
