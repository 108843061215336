import { Tab } from "../../components/Tab";
import PageWrap from "../../components/Pagewrap";
import { useLocation } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import {
  getFolderList,
  getMailCountByFile,
  getMailCountByStatus,
  getMailList,
} from "../../api";
import Toolbar from "./Toolbar";
import { menuContext, searchContext, selectedContext } from "../../context";
import { MailListItem } from "../../components/MailListItem";
import Folder from "../../components/Folder";
import CustomDialog from "../../components/CustomDialog";
import { Button } from "@mui/material";
import { useCheckedAll, useSearch, useShowPopup } from "../../hooks";
import CustomAlertDialog from "../../components/CustomAlertDialog";
import { formateOffset } from "../../utils";

export default function Mail() {
  const location = useLocation();
  const { pathname, search } = location;
  const status = pathname.replace("/mail/", "");
  const edm_file_id = search.replace("?", "");

  const [folders, setFolderList] = useState([]);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastTimestamp, setLastTimestamp] = useState(formateOffset(new Date()));
  const [hasMore, setHasMore] = useState(true);

  const loadMoreData = async (status, edm_file_id, lastTimestamp, hasMore) => {
    if (loading || !hasMore) return;

    setLoading(true);
    try {
      const response = await getMailList(
        status,
        edm_file_id.length === 0 ? null : parseInt(edm_file_id),
        lastTimestamp
      );

      const newData = response.data;
      setData((prevData) => [...prevData, ...newData]);

      if (newData.length > 0) {
        const newLastTimestamp =
          status === "sended"
            ? newData[newData.length - 1].create_date
            : newData[newData.length - 1].update_date;
        setLastTimestamp(newLastTimestamp);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMailCountByStatus().then((response) => {
      if (response.success === false) {
        setFalse(true, response.errorMesg);
      } else {
        setMailCount((prev) => ({ ...prev, status: response.data }));
      }
    });

    if (status === "sended") {
      getFolderList().then((response) => {
        if (response.success === false) {
          setFalse(true, response.errorMesg);
        } else {
          setFolderList(response.data);
        }
      });
      getMailCountByFile().then((response) => {
        if (response.success === false) {
          setFalse(true, response.errorMesg);
        } else {
          setMailCount((prev) => ({ ...prev, edm_file: response.data }));
        }
      });
    }

    setData([]);
    setLoading(false);
    setLastTimestamp(formateOffset(new Date()));
    setHasMore(true);
    loadMoreData(status, edm_file_id, formateOffset(new Date()), true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, edm_file_id]);

  const [mailCount, setMailCount] = useState({ status: 0, edm_file: 0 });

  const { getSearchValue, setSearchValue } = useSearch();
  const searchValue = getSearchValue();
  const { setFalse, getShowPopup, setIsShow } = useShowPopup();
  const {
    setCheckState,
    getCheckState,
    getSelectedItems,
    setSelectedItemsState,
  } = useCheckedAll(data.length);
  const selectedItems = getSelectedItems();

  const { setMenu } = useContext(menuContext);

  useEffect(() => {
    setMenu({ text: ["電子報", "信件管理"], href: ["/", "/mail/sended"] });
  }, [setMenu]);

  return (
    <PageWrap
      header={"信件管理"}
      headerChildren={
        <Button
          className="btn svg-btn"
          href="/mail/newmail"
          sx={{
            color: "var(--color-neutral-900)",
            bgcolor: "var(--color-secondary-4)",
            fontSize: 16,
            fontWeight: "normal",
            ":hover": {
              bgcolor: "var(--color-secondary-3)",
            },
          }}
        >
          <i className="ri-add-line"></i>建立新信件
        </Button>
      }
      tabs={
        <>
          <Tab
            text={`已寄送 (${mailCount.status?.send || 0})`}
            href={"sended"}
            isActive={pathname === "/mail/sended"}
          />
          <Tab
            text={`待寄送 (${mailCount.status?.schedule || 0})`}
            href={"scheduled"}
            isActive={pathname === "/mail/scheduled"}
          />
          <Tab
            text={`草稿 (${mailCount.status?.draft || 0})`}
            href={"draft"}
            isActive={pathname === "/mail/draft"}
          />
        </>
      }
    >
      <searchContext.Provider value={{ searchValue, setSearchValue }}>
        <selectedContext.Provider
          value={{
            setCheckState,
            getCheckState,
            selectedItems,
            setSelectedItemsState,
          }}
        >
          {pathname === "/mail/sended" && search.length === 0 && (
            <ul className="mt-6 w-full flex gap-6 flex-wrap">
              <li className="folder">
                <CustomDialog
                  className="w-full !py-5 !justify-start !bg-transparent"
                  variant="contained"
                  buttonTitle={
                    <div className="flex items-center text-[var(--color-primary-1)]">
                      <i className="ri-add-line mr-4"></i>
                      新增資料夾
                    </div>
                  }
                  dialogTitle={"新增資料夾"}
                  inputProps={{
                    url: "/api/mail/management/create/edm_file",
                    method: "post",
                    props: { file_name: "" },
                  }}
                  reload={true}
                />
              </li>
              {/* <openContext.Provider value={{}}> */}
              {folders.map((folder) => {
                return (
                  <Folder
                    key={folder.edm_file_id}
                    id={folder.edm_file_id}
                    title={
                      folder.file_name +
                      `(${
                        mailCount.edm_file
                          ? mailCount.edm_file[folder.edm_file_id]
                          : 0
                      })`
                    }
                  />
                );
              })}
              {/* </openContext.Provider> */}
            </ul>
          )}
          {folders.length > 0 && search.length !== 0 && (
            <h3 className="mt-6">
              {
                folders.filter(
                  (item) =>
                    item.edm_file_id === parseInt(search.replace("?", ""))
                )[0].file_name
              }
              ({mailCount.edm_file[search.replace("?", "")] || 0})
            </h3>
          )}
          <Toolbar folders={folders} mails={data} />
          <ul className="flex flex-col gap-3 mt-6">
            {data
              .filter((item) => item.title.includes(searchValue))
              .map((item) => (
                <MailListItem key={item.edm_id} {...item} />
              ))}
          </ul>
          {loading && <p className="text-center mt-6">載入中...</p>}
          {!loading && hasMore && (
            <div className="flex justify-center mt-6">
              <button
                type="button"
                onClick={() =>
                  loadMoreData(status, edm_file_id, lastTimestamp, hasMore)
                }
              >
                載入更多
              </button>
            </div>
          )}
          {!hasMore && <p className="text-center mt-6">沒有更多資料</p>}
        </selectedContext.Provider>
      </searchContext.Provider>
      {getShowPopup().show && (
        <CustomAlertDialog
          show={getShowPopup().show}
          message={getShowPopup().message}
          confirmFunction={() => setIsShow(false)}
        />
      )}
    </PageWrap>
  );
}
