import { useContext, useEffect, useState } from "react";
import { searchContext, selectedContext } from "../../context";
import { deleteMails, moveMailToFolder } from "../../api";
import CustomDialog from "../../components/CustomDialog";
import { useLocation } from "react-router-dom";
import CustomModal from "../../components/CustomModal";
import { useShowPopup } from "../../hooks";
import CustomAlertDialog from "../../components/CustomAlertDialog";
import { CheckAllButton } from "../../components/Button";
import SearchInput from "../../components/SearchInput";

/**
 * 信件工具欄(全選、移動、刪除、搜尋)
 * @param {Array} props
 */
export default function Toolbar(props) {
  const { folders, mails } = props;
  const data = mails.map((item) => item.edm_id);
  const [selectedFolderId, setSelectedFolderId] = useState();
  const { setCheckState, getCheckState, selectedItems, setSelectedItemsState } =
    useContext(selectedContext);
  const { searchValue, setSearchValue } = useContext(searchContext);
  const { setFalse, getShowPopup, setIsShow } = useShowPopup();

  const location = useLocation();

  useEffect(() => {
    if (!(selectedItems.length === data.length && data.length !== 0)) {
      setCheckState(false);
      setSelectedItemsState(false, [], []);
    } else {
      setCheckState(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.length]);

  /**
   * 全選
   */
  const checkedAllHandler = (e) => {
    let isSelected = e.target.checked;
    setSelectedItemsState(isSelected, data, []);
  };

  /**
   * 選取資料夾
   */
  const handleClick = (e) => {
    const id = folders.filter((item) => item.file_name === e.target.innerText);
    if (id.length > 0) {
      setSelectedFolderId(id[0].edm_file_id);
    } else {
      setSelectedFolderId(null);
    }
  };

  const handleCancel = () => {
    setSelectedFolderId();
  };

  const handleConfirm = () => {
    moveMailToFolder(selectedItems, selectedFolderId).then((response) => {
      if (response.success === false) {
        setFalse(true, response.errorMesg);
      } else {
        window.location.reload();
      }
    });
  };

  return (
    <>
      <div className="flex justify-between mt-6">
        <div className="flex gap-6 items-center">
          <CheckAllButton
            data={props}
            state={getCheckState()}
            onChange={checkedAllHandler}
          />
          {location.pathname === "/mail/sended" && (
            <CustomModal
              className="move-btn"
              disabled={selectedItems.length > 0 ? false : true}
              buttonTitle={"移動"}
              modalTitle={"移動至"}
              cancelFunction={handleCancel}
              confirmFunction={handleConfirm}
              reload={true}
            >
              <ul className="mt-6">
                {location.search.length > 0 && (
                  <li
                    className={
                      "hover:bg-[var(--color-primary-4)] " +
                      (selectedFolderId === null &&
                        "bg-[var(--color-neutral-100)]")
                    }
                  >
                    <button
                      className="w-full px-4 py-[10px] flex items-center"
                      onClick={handleClick}
                    >
                      <i className="ri-inbox-2-fill leading-none text-[var(--color-neutral-400)] mr-[10px]"></i>
                      <span className="text-[var(--color-neutral-900)] p3">
                        已寄送
                      </span>
                    </button>
                  </li>
                )}
                {folders
                  .filter(
                    (item) =>
                      item.edm_file_id !==
                      parseInt(location.search.replace("?", ""))
                  )
                  .map((folder, index) => {
                    return (
                      <li
                        key={index}
                        className={
                          "hover:bg-[var(--color-primary-4)] hover:text-[var(--color-primary-1)] " +
                          (selectedFolderId === folder.edm_file_id
                            ? "bg-[var(--color-neutral-100)]"
                            : "")
                        }
                      >
                        <button
                          className="w-full px-4 py-[10px] flex items-center"
                          onClick={handleClick}
                        >
                          <i className="ri-folder-fill leading-none text-[var(--color-neutral-400)] mr-[10px]"></i>
                          <span
                            className={
                              "p3 " +
                              (selectedFolderId === folder.edm_file_id
                                ? "text-[var(--color-primary-1)]"
                                : "text-[var(--color-neutral-900)]")
                            }
                          >
                            {folder.file_name}
                          </span>
                        </button>
                      </li>
                    );
                  })}
              </ul>
            </CustomModal>
          )}
          <CustomDialog
            className="delete-btn"
            disabled={selectedItems.length > 0 ? false : true}
            buttonTitle={"刪除"}
            confirmFuncion={() => deleteMails(selectedItems)}
            dialogTitle={"確定要刪除嗎？"}
            reload={true}
          />
          <span>選取: {selectedItems.length}筆</span>
        </div>
        <SearchInput
          type={location.pathname}
          searchValue={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </div>
      {getShowPopup().show && (
        <CustomAlertDialog
          show={getShowPopup().show}
          message={getShowPopup().message}
          confirmFunction={() => setIsShow(false)}
        />
      )}
    </>
  );
}
