const BorderBuuton = ({
  type,
  text,
  icon,
  onclick,
  disabled,
  custom,
  ...props
}) => {
  return (
    <button
      type={type}
      onClick={onclick}
      disabled={disabled}
      className={`button border-b ${icon && "icon"} ${custom}`}
      {...props}
    >
      {icon}
      {text}
    </button>
  );
};

export default BorderBuuton;
