import { Button, Checkbox, FormControlLabel } from "@mui/material";
import { VisuallyHiddenInput } from "../../utils";

export const PrimaryButton = ({ text, margin, disabled, onClick }) => {
  return (
    <Button
      variant="contained"
      sx={{
        minWidth: "auto",
        bgcolor: "var(--color-primary-1)",
        color: "var(--color-neutral-50)",
        fontSize: 16,
        fontWeight: "normal",
        lineHeight: 1.5,
        borderRadius: 1.5,
        letterSpacing: 0,
        px: 6,
        py: 1.25,
        ml: !margin ? "16px!important" : margin,
        boxShadow: "none",
        ":hover": { bgcolor: "var(--color-primary-2)", boxShadow: "none" },
        ":disabled": {
          color: "var(--color-neutral-50)",
          bgcolor: "#CECECE",
        },
      }}
      disabled={disabled}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export const SecondaryButton = ({ text, classname, disabled, onClick }) => {
  return (
    <Button
      variant="contained"
      sx={{
        minWidth: "auto",
        color: "var(--color-neutral-900)",
        fontSize: 16,
        fontWeight: "normal",
        lineHeight: 1.5,
        borderRadius: 1.5,
        letterSpacing: 0,
        boxShadow: "none",
        ":hover": { boxShadow: "none" },
      }}
      className={classname}
      disabled={disabled}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export const ThirdButton = ({ text, margin, disabled, onClick }) => {
  return (
    <Button
      variant="outlined"
      sx={{
        minWidth: "auto",
        bgcolor: "transparent",
        color: "var(--color-primary-1)",
        border: "none",
        outline: "1px solid var(--color-primary-1)",
        fontSize: 16,
        fontWeight: "normal",
        lineHeight: 1.5,
        borderRadius: 1.5,
        letterSpacing: 0,
        px: 6,
        py: 1.25,
        ml: margin,
        boxShadow: "none",
        ":hover": {
          border: "none",
          bgcolor: "var(--color-primary-5)",
          outlineColor: "var(--color-primary-2)",
          boxShadow: "none",
        },
        ":disabled": {
          color: "var(--color-neutral-200)",
          border: "none",
          bgcolor: "var(--color-neutral-50)",
          outlineColor: "var(--color-neutral-200)",
          boxShadow: "none",
        },
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};

export const UploadButton = ({ text, accept, onChange }) => {
  return (
    <Button
      className="btn svg-btn !normal-case"
      component="label"
      variant="contained"
      sx={{
        fontSize: 16,
        bgcolor: "var(--color-secondary-4)",
        color: "var(--color-neutral-900)",
        fontWeight: "normal",
        boxShadow: "none",
        letterSpacing: 0,
        ":hover": {
          bgcolor: "var(--color-secondary-3)",
          boxShadow: "none",
        },
      }}
    >
      {text}
      <VisuallyHiddenInput type="file" accept={accept} onChange={onChange} />
    </Button>
  );
};

export const SortButton = ({ props }) => {
  return (
    <div className="flex flex-col">
      <i
        className={
          "ri-arrow-up-s-fill text-lg leading-[10px] text-[var(--color-neutral-400)] " +
          (props === 1 ? "text-[var(--color-neutral-900)]" : "")
        }
      ></i>
      <i
        className={
          "ri-arrow-down-s-fill text-lg leading-[10px] text-[var(--color-neutral-400)] " +
          (props === 2 ? "text-[var(--color-neutral-900)]" : "")
        }
      ></i>
    </div>
  );
};

export const CheckAllButton = ({ data, state, onChange }) => {
  return (
    <Button
      variant="contained"
      sx={{
        color: "var(--color-neutral-900)",
        fontWeight: "normal",
        bgcolor: "var(--color-primary-5)",
        boxShadow: "none",
        p: 0,
        ":hover": {
          bgcolor: "var(--color-primary-4)",
          boxShadow: "none",
        },
        ":disabled": {
          bgcolor: "#CECECE",
          color: "var(--color-neutral-50)",
        },
      }}
      disabled={data?.length === 0 ? true : false}
    >
      <FormControlLabel
        control={<Checkbox sx={{ mr: 1.25 }} />}
        label={"全選"}
        sx={{
          m: 0,
          py: 0.75,
          pl: 1.25,
          pr: 2,
          "& .MuiCheckbox-root": { p: 0 },
        }}
        checked={state}
        onChange={onChange}
      />
    </Button>
  );
};
