import { Tab } from "../../components/Tab";
import PageWrap from "../../components/Pagewrap";
import { useContext, useEffect, useState } from "react";
import { TabContent } from "../../components/TabContent";
import GroupList from "./Group";
import ImportList from "./ImportList";
import { useLocation } from "react-router-dom";
import { menuContext, teamContext } from "../../context";
import PersonList from "./PersonList";
import { getTeamList } from "../../api";
import { useShowPopup } from "../../hooks";
import CustomAlertDialog from "../../components/CustomAlertDialog";

export default function ReceiverList() {
  const location = useLocation();
  const [teamList, setTeamList] = useState([]);

  const { setFalse, getShowPopup, setIsShow } = useShowPopup();

  const { setMenu } = useContext(menuContext);

  useEffect(() => {
    setMenu({
      text: ["電子報", "名單管理"],
      href: ["/", "/receiverlist/grouplist"],
    });
  }, [setMenu]);

  useEffect(() => {
    getTeamList().then((response) => {
      if (response.success === false) {
        setFalse(true, response.errorMesg);
      } else {
        setTeamList(response.data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrap
      header={"名單管理"}
      tabs={
        <>
          <Tab
            href={"grouplist"}
            text={"我的群組"}
            isActive={location.pathname.includes("/receiverlist/grouplist")}
          />
          <Tab
            href={"personlist"}
            text={"所有名單"}
            isActive={location.pathname === "/receiverlist/personlist"}
          />
          <Tab
            href={"importlist"}
            text={"匯入名單"}
            isActive={location.pathname === "/receiverlist/importlist"}
          />
        </>
      }
    >
      <teamContext.Provider value={{ teamList, setTeamList }}>
        <TabContent
          isActive={location.pathname.includes("/receiverlist/grouplist")}
          children={<GroupList teamList={teamList} />}
        />
        <TabContent
          isActive={location.pathname === "/receiverlist/personlist"}
          children={<PersonList teamList={teamList} />}
        />
        <TabContent
          isActive={location.pathname === "/receiverlist/importlist"}
          children={<ImportList teamList={teamList} />}
        />
      </teamContext.Provider>
      {getShowPopup().show && (
        <CustomAlertDialog
          show={getShowPopup().show}
          message={getShowPopup().message}
          confirmFunction={() => setIsShow(false)}
        />
      )}
    </PageWrap>
  );
}
