import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  baseUrl,
  createReceiversByFile,
  createTeam,
  getCreateReceiversExample,
} from "../../../api";
import ImportFailed from "../../../components/ImportFailed";
import CustomAlertDialog from "../../../components/CustomAlertDialog";
import { useShowPopup } from "../../../hooks";
import PrimaryButton from "../../../components/Button/PrimaryButton";
import BorderBuuton from "../../../components/Button/BorderBuuton";
import SecondaryButton from "../../../components/Button/SecondaryButton";

export default function ImportList({ teamList }) {
  const [formData, setFormData] = useState({});
  const { mode, team, name } = formData;
  const [loading, setLoading] = useState(false);
  const [showTeamList, setShowTeamList] = useState(false);
  const [failedData, setFailedData] = useState();

  const { setFalse, getShowPopup, setIsShow } = useShowPopup();

  const navigate = useNavigate();

  /**
   * 範例下載
   */
  const handleDownload = () => {
    getCreateReceiversExample().then((response) => {
      if (response.success === false) {
        setFalse(true, response.errorMesg);
      } else {
        let a = document.createElement("a");
        a.href = baseUrl + response.data.url;
        a.download = "result";
        a.click();
      }
    });
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    if (name === "cancel") {
      setFormData({});
    } else {
      setFormData((formData) => ({ ...formData, [name]: value }));
    }
    if (name === "file") {
      setFormData((prev) => ({ ...prev, [name]: e.target.files[0] }));
    }
    if (name === "team") {
      setShowTeamList(false);
    }
    if (name === "mode") {
      setFormData((prev) => {
        delete prev.team;
        delete prev.name;
        return prev;
      });
    }
    if (name === "name" && value.length === 0) {
      setFormData((prev) => {
        delete prev.name;
        return prev;
      });
    }
  };

  const handleSubmit = (e) => {
    const fetchData = async (formdata) => {
      setLoading(true);
      const response = await createReceiversByFile(formdata);
      if (response.success === false) {
        setFalse(true, response.errorMesg);
      } else {
        setFormData({});
        document.querySelector(".result-containter").classList.remove("hidden");
        setTimeout(() => {
          setFailedData((prev) => ({
            ...prev,
            totalLength:
              response.data.success_count + response.data.failed_emails.length,
            success_count: response.data.success_count,
            failedData: response.data.failed_emails,
          }));
          setLoading(false);
        }, 3000);
      }
    };

    e.preventDefault();
    const data = new FormData(e.target);

    if (teamList.filter((item) => item.name === name).length > 0) {
      setFalse(true, "群組名稱已重複，請更改群組名稱");
      return;
    }

    const file = data.get("file");
    const formdata = new FormData();
    formdata.append("file", file);

    if (mode === "origin" && team && team.length > 0) {
      const { team_id } = JSON.parse(team);
      formdata.append("team_id", JSON.stringify([team_id]));
      fetchData(formdata);
    } else if (mode === "create" && name && name.length > 0) {
      createTeam(name).then((response) => {
        if (response.success === false) {
          setFalse(true, response.errorMesg);
        } else {
          formdata.append("team_id", JSON.stringify([response.data.team_id]));
          fetchData(formdata);
        }
      });
    }
  };

  /**
   * 回到名單
   */
  const handleBack = () => {
    setFailedData();
    navigate("/receiverlist/grouplist");
    setTimeout(() => {
      window.location.reload();
    }, 300);
  };

  /**
   * 重新上傳
   */
  const handleReupload = () => {
    setFailedData();
    setFormData({});
  };

  if (failedData) {
    return (
      <ImportFailed
        type={"receiver"}
        data={failedData}
        handleBack={handleBack}
        handleReupload={handleReupload}
      />
    );
  }

  return (
    <>
      <div className="bg-white">
        <form className="p-9 w-4/5 xl:w-3/5" onSubmit={handleSubmit}>
          <div className="mb-2.5">
            <p className="p3 mb-2.5">
              上傳 .xlsx 檔案<span className="req">*</span>
            </p>
            <div className="flex items-start">
              <input
                type="file"
                name="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                className="flex-1 border border-solid border-[var(--color-neutral-400)]"
                required
                onChange={handleFormChange}
              />
              <SecondaryButton
                type={"button"}
                text={"範例下載"}
                custom={"ml-4"}
                onClick={handleDownload}
              />
            </div>
          </div>
          <div>
            <p className="p3 mb-2.5">
              加入群組<span className="req">*</span>
            </p>
            <div className="flex items-center gap-9 mb-2.5">
              <label>
                <input
                  type="radio"
                  name="mode"
                  value={"origin"}
                  className="mr-2"
                  required
                  onChange={handleFormChange}
                />
                既有群組
              </label>
              <label>
                <input
                  type="radio"
                  name="mode"
                  value={"create"}
                  className="mr-2"
                  required
                  onChange={handleFormChange}
                />
                新增群組
              </label>
            </div>
          </div>
          {mode === "origin" && (
            <div className="relative border border-solid border-[var(--color-neutral-400)] py-[9px] px-4 rounded-[3px] mb-2.5">
              <button
                type="button"
                className="w-full flex items-center justify-between"
                onClick={() => setShowTeamList(!showTeamList)}
              >
                <span
                  className={`text-left flex-1 ${
                    !team && "text-[var(--color-neutral-400)]"
                  }`}
                >
                  {team ? JSON.parse(team).name : "選擇既有群組"}
                </span>
                <i className="ri-arrow-down-s-line leading-none"></i>
              </button>
              {showTeamList && (
                <ul className="absolute rounded-[3px] left-0 top-full z-10 bg-white w-full shadow">
                  {teamList.map((team) => (
                    <li key={team.id} className="w-full">
                      <label className="px-[30px] mb-0 py-2 cursor-pointer hover:bg-[var(--color-primary-4)] hover:text-[var(--color-primary-1)]">
                        <input
                          type="radio"
                          name="team"
                          value={JSON.stringify(team)}
                          className="hidden"
                          required={mode === "origin"}
                          onChange={handleFormChange}
                        />
                        {team.name}
                      </label>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
          {mode === "create" && (
            <label className="mb-2.5">
              <input
                type="text"
                name="name"
                value={name}
                className="w-full py-[9px] border border-solid border-[var(--color-neutral-400)]"
                placeholder="請填寫群組名稱"
                required={mode === "create"}
                onChange={handleFormChange}
              />
            </label>
          )}
          <div className="flex items-center gap-[30px]">
            <PrimaryButton type={"submit"} text={"匯入"} disabled={loading} />
            <BorderBuuton
              type={"button"}
              text={"取消"}
              name={"cancel"}
              disabled={loading}
              onclick={handleFormChange}
            />
          </div>
          <div className="result-containter hidden">
            <p className="p3 my-2.5 text-[var(--color-alert-500)]">
              匯入中，請稍後...
            </p>
            <div className="progress-container">
              <div className="progress progress-animate"></div>
            </div>
          </div>
        </form>
      </div>
      {getShowPopup().show && (
        <CustomAlertDialog
          show={getShowPopup().show}
          message={getShowPopup().message}
          confirmFunction={() => setIsShow(false)}
        />
      )}
    </>
  );
}
