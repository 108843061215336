import { Backdrop, Button, CircularProgress } from "@mui/material";
import CustomDialog from "../../../components/CustomDialog";
import { useEffect, useRef, useState } from "react";
import { sortFunc } from "../../../utils";
import {
  baseUrl,
  deleteReceivers,
  exportParticularReceivers,
  getParticularTeamReceiver,
} from "../../../api";
import { selectedContext } from "../../../context";
import PersonItem from "../PersonItem";
import { FixedSizeList } from "react-window";
import { CheckAllButton, SortButton } from "../../../components/Button";
import { useCheckedAll, useShowPopup, useSort } from "../../../hooks";
import CustomAlertDialog from "../../../components/CustomAlertDialog";

const status = [
  { name: "正常", value: "normal", count: 0 },
  { name: "無效信箱", value: "invalid", count: 0 },
  { name: "被封鎖", value: "blocked", count: 0 },
  { name: "黑名單", value: "blacked", count: 0 },
];

export default function PersonList({ teamList }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [receiverList, setReceiverList] = useState([]);
  const [filteredList, setFilteredList] = useState();
  const [statusList, setStatusList] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState({ id: 0, name: "所在群組" });
  const [selectedStatus, setSelectedStatus] = useState("會員狀態");
  const [keyword, setKeyword] = useState("");
  const [showTeamList, setShowTeamList] = useState(false);
  const [showStatusList, setShowStatusList] = useState(false);

  const { setFalse, getShowPopup, setIsShow } = useShowPopup();
  const { getClicked, updateClicked, sortByPosition } = useSort(5);
  const {
    setCheckState,
    setSelectedItemsState,
    getCheckState,
    getSelectedItems,
  } = useCheckedAll(filteredList ? filteredList.length : receiverList.length);
  const selectedItems = getSelectedItems();

  const teamRef = useRef();
  const statusRef = useRef();
  const handleBackdropClick = (e) => {
    if (teamRef.current && !teamRef.current.contains(e.target)) {
      setShowTeamList(false);
    }
    if (statusRef.current && !statusRef.current.contains(e.target)) {
      setShowStatusList(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getParticularTeamReceiver().then((response) => {
      if (response.success === false) {
        setFalse(true, response.errorMesg);
      } else {
        setStatusList(response.data[0]);
        status.forEach((item) => {
          item.count = response.data[0][`${item.value}_array`].length;
        });
        setReceiverList(response.data.filter((item, index) => index !== 0));
        setData(response.data.filter((item, index) => index !== 0));
      }
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowTeamList = () => {
    setShowTeamList((prev) => !prev);
  };

  const handleShowStatusList = () => {
    setShowStatusList((prev) => !prev);
  };

  /**
   * 設定所在群組條件
   */
  const handleSetSelectedTeam = (e) => {
    setSelectedTeam({ id: parseInt(e.target.id), name: e.target.innerText });
    setShowTeamList(false);
  };

  /**
   * 設定會員狀態條件
   */
  const handleSetSelectedStatus = (e) => {
    setSelectedStatus(e.target.innerText.split("(")[0]);
    setShowStatusList(false);
  };

  /**
   * 設定關鍵字
   */
  const handleSetKeyword = (e) => {
    setKeyword(e.target.value);
  };

  /**
   * 排序聯絡人
   */
  const handleClick = (e) => {
    let position = parseInt(e.target.value);
    const updateClick = updateClicked(position);
    sortByPosition(updateClick);
    let list = [];
    data.map((result) => {
      list = list.concat(result);
      return list;
    });
    if (filteredList) {
      sortFunc(updateClick[position], filteredList, e.target.name, () =>
        setFilteredList(list)
      );
    } else {
      sortFunc(updateClick[position], receiverList, e.target.name, () =>
        setReceiverList(list)
      );
    }
  };

  /**
   * 全選
   */
  const checkAllHandler = (e) => {
    let isSelected = e.target.checked;
    let postIds;
    if (filteredList) {
      postIds = filteredList.map((item) => {
        return item.receiver_id;
      });
    } else {
      postIds = receiverList.map((item) => {
        return item.receiver_id;
      });
    }
    setSelectedItemsState(isSelected, postIds, []);
  };

  /**
   * 匯出選取的聯絡人
   */
  const handleExport = () => {
    exportParticularReceivers(getSelectedItems()).then((response) => {
      if (response.success === false) {
      } else {
        let a = document.createElement("a");
        a.href = baseUrl + response.data.url;
        a.download = "result";
        a.click();
      }
    });
  };

  const handleSearch = () => {
    setCheckState(false);
    setSelectedItemsState(false, [], []);
    setFilteredList(
      receiverList
        .filter(
          (item) =>
            item.receiver_name.includes(keyword) ||
            item.email.includes(keyword) ||
            (item.phone !== null && item.phone.includes(keyword)) ||
            (item.unit !== null && item.unit.includes(keyword)) ||
            (item.remark !== null && item.remark.includes(keyword))
        )
        .filter((item) => {
          if (selectedTeam.id !== 0) {
            const result = item.team_id_array.filter(
              (team) => team.team_id === selectedTeam.id
            );
            if (result.length > 0) {
              return item;
            } else {
              return null;
            }
            // return item.team_id_array
          } else {
            return item;
          }
        })
        .filter((item) => {
          if (selectedStatus !== "會員狀態") {
            return (
              item.status === "normal"
                ? "正常"
                : item.status === "invalid"
                ? "無效信箱"
                : "被封鎖"
            ).includes(selectedStatus);
          } else {
            return item;
          }
        })
    );
    setData(
      receiverList
        .filter(
          (item) =>
            item.receiver_name.includes(keyword) ||
            item.email.includes(keyword) ||
            (item.phone !== null && item.phone.includes(keyword)) ||
            (item.unit !== null && item.unit.includes(keyword)) ||
            (item.remark !== null && item.remark.includes(keyword))
        )
        .filter((item) => {
          if (selectedTeam.id !== 0) {
            return item.team_id_array.indexOf(selectedTeam.id) > -1;
          } else {
            return item;
          }
        })
        .filter((item) => {
          if (selectedStatus !== "會員狀態") {
            return (
              statusList[
                `${
                  status.filter((s) => s.name === selectedStatus)[0].value
                }_array`
              ].indexOf(item.receiver_id) > -1
            );
          } else {
            return item;
          }
        })
    );
  };

  /**
   * 清除條件
   */
  const handleClear = () => {
    setSelectedTeam({ id: 0, name: "所在群組" });
    setSelectedStatus("會員狀態");
    setKeyword("");
  };

  const item = ({ index, style, data }) => (
    <PersonItem key={index} style={style} form={data[index]} />
  );

  return (
    <selectedContext.Provider value={{ selectedItems, setSelectedItemsState }}>
      <div
        className="w-full bg-[var(--color-neutral-50)] p-9 mb-6"
        onClick={handleBackdropClick}
      >
        <h2 className="mb-6">所有名單({receiverList.length})</h2>
        <div className="w-full flex items-center gap-6">
          <span>篩選條件：</span>
          <div className="relative" ref={teamRef}>
            <span
              className="cursor-pointer border-[1px] border-[var(--color-neutral-400)] rounded-[3px] bg-[var(--color-neutral-50)] px-4 flex items-center justify-between w-[200px] text-[var(--color-neutral-800)]"
              onClick={handleShowTeamList}
            >
              {selectedTeam.name}
              <i className="ri-arrow-drop-down-line text-2xl"></i>
            </span>
            <ul
              className={
                "option-list max-h-40 overflow-auto " +
                (!showTeamList && "hidden")
              }
            >
              {teamList.map((item, index) => (
                <li
                  key={index}
                  className="dropdown cursor-pointer"
                  onClick={handleSetSelectedTeam}
                  id={item.team_id}
                >
                  {item.name}
                </li>
              ))}
            </ul>
          </div>
          <div className="relative" ref={statusRef}>
            <span
              className="cursor-pointer border-[1px] border-[var(--color-neutral-400)] rounded-[3px] bg-[var(--color-neutral-50)] px-4 flex items-center justify-between w-[200px] text-[var(--color-neutral-800)]"
              onClick={handleShowStatusList}
            >
              {selectedStatus}
              <i className="ri-arrow-drop-down-line text-2xl"></i>
            </span>
            <ul className={"option-list " + (!showStatusList && "hidden")}>
              {status.map((item, index) => (
                <li
                  key={index}
                  className="dropdown cursor-pointer"
                  onClick={handleSetSelectedStatus}
                >
                  {item.name}({item.count})
                </li>
              ))}
            </ul>
          </div>
          <span>關鍵字搜尋：</span>
          <input
            className="flex-1 outline outline-1 outline-[var(--color-neutral-400)]"
            placeholder="Email、聯絡人、電話..."
            value={keyword}
            onChange={handleSetKeyword}
          />
        </div>
        <div className="w-full flex items-center justify-end mt-6">
          <Button
            sx={{
              fontSize: 16,
              lineHeight: 1.5,
              px: 6,
              py: 0.75,
              borderRadius: 0.75,
              color: "var(--color-neutral-400)",
              bgcolor: "transparent",
              fontWeight: "normal",
              ":hover": { bgcolor: "transparent" },
            }}
            onClick={handleClear}
            disabled={
              selectedTeam.id === 0 &&
              selectedStatus === "會員狀態" &&
              keyword.length === 0
            }
          >
            <i className="ri-delete-bin-7-line"></i>
            <span className="underline">清除條件</span>
          </Button>
          <Button
            sx={{
              fontSize: 16,
              lineHeight: 1.5,
              px: 6,
              py: 0.75,
              borderRadius: 0.75,
              color: "var(--color-neutral-50)",
              bgcolor: "var(--color-primary-1)",
              ":hover": { bgcolor: "var(--color-primary-2)" },
            }}
            onClick={handleSearch}
          >
            查詢
          </Button>
        </div>
      </div>
      <div
        className="w-full bg-[var(--color-neutral-50)] p-9"
        onClick={handleBackdropClick}
      >
        <div className="flex justify-between items-center mb-6">
          <div className="flex gap-6 items-center">
            <CheckAllButton
              data={receiverList}
              state={getCheckState()}
              onChange={checkAllHandler}
            />
            <CustomDialog
              className="delete-btn"
              disabled={getSelectedItems().length === 0 ? true : false}
              buttonTitle={"刪除"}
              confirmFuncion={() => deleteReceivers(null, getSelectedItems())}
              dialogTitle={"確定要刪除嗎？"}
              dialogContent={"從所有群組中移除，將無法復原"}
              reload={true}
            />
            <span>選取: {getSelectedItems().length}筆</span>
            <span>
              符合結果:{" "}
              {filteredList ? filteredList.length : receiverList.length}筆
            </span>
          </div>
          <Button
            className="btn svg-btn third-btn !pl-[10px] !pr-4 !py-[6px] h-9"
            disabled={getSelectedItems().length === 0}
            sx={{
              bgcolor: "var(--color-primary-4)",
              color: "var(--color-neutral-900)",
              fontWeight: "normal",
              fontSize: 16,
              ":hover": { bgcolor: "var(--color-primary-3)" },
            }}
            onClick={handleExport}
          >
            <i className="ri-file-excel-2-line text-2xl"></i>
            匯出名單
          </Button>
        </div>
        {loading ? (
          <Backdrop
            sx={{ zIndex: 999 }}
            open={loading}
            onClick={handleBackdropClick}
          >
            <CircularProgress />
          </Backdrop>
        ) : (
          <>
            <div className="flex items-center py-4 px-10 bg-[var(--color-neutral-100)]">
              <span className="w-[10%] font-bold mr-1">選取</span>
              <span className="w-[15%] font-bold mr-1">
                <label className="flex items-center gap-[2px] m-0 cursor-pointer">
                  <input
                    type="button"
                    name="unit"
                    value={0}
                    className="absolute top-0 invisible"
                    onClick={handleClick}
                  />
                  單位
                  <SortButton props={getClicked(0)} />
                </label>
              </span>
              <span className="w-[20%] font-bold mr-1">
                <label className="flex items-center gap-[2px] m-0 cursor-pointer">
                  <input
                    type="button"
                    name="receiver_name"
                    value={1}
                    className="absolute top-0 invisible"
                    onClick={handleClick}
                  />
                  聯絡人
                  <SortButton props={getClicked(1)} />
                </label>
              </span>
              <span className="w-[30%] font-bold mr-1">
                <label className="flex items-center gap-[2px] m-0 cursor-pointer">
                  <input
                    type="button"
                    name="email"
                    value={2}
                    className="absolute top-0 invisible"
                    onClick={handleClick}
                  />
                  Email
                  <SortButton props={getClicked(2)} />
                </label>
              </span>
              <span className="w-[15%] font-bold mr-1">
                <label className="flex items-center gap-[2px] m-0 cursor-pointer">
                  <input
                    type="button"
                    name="phone"
                    value={3}
                    className="absolute top-0 invisible"
                    onClick={handleClick}
                  />
                  電話
                  <SortButton props={getClicked(3)} />
                </label>
              </span>
              <span className="w-[10%] font-bold">
                <label className="flex items-center gap-[2px] m-0 cursor-pointer">
                  <input
                    type="button"
                    name="status"
                    value={4}
                    className="absolute top-0 invisible"
                    onClick={handleClick}
                  />
                  狀態
                  <SortButton props={getClicked(4)} />
                </label>
              </span>
            </div>
            <FixedSizeList
              height={
                filteredList
                  ? window.innerHeight > filteredList.length * 68
                    ? filteredList.length * 68
                    : window.innerHeight - 152
                  : window.innerHeight > receiverList.length * 68
                  ? receiverList.length * 68
                  : window.innerHeight - 152
              }
              itemCount={
                filteredList ? filteredList.length : receiverList.length
              }
              itemSize={68}
              width={"100%"}
              itemData={filteredList ? filteredList : receiverList}
            >
              {item}
            </FixedSizeList>
          </>
        )}
      </div>
      {getShowPopup().show && (
        <CustomAlertDialog
          show={getShowPopup().show}
          message={getShowPopup().message}
          confirmFunction={() => setIsShow(false)}
        />
      )}
    </selectedContext.Provider>
  );
}
