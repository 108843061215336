import { useEffect, useState } from "react";
import { getLocalStorage, setTeamPosition } from "../../../utils";
import { Link, useNavigate, useParams } from "react-router-dom";
import CustomDialog from "../../../components/CustomDialog";
import { ReactSortable } from "react-sortablejs";
import ReceiverInnerPage from "./InnerPage";
import SecondaryButton from "../../../components/Button/SecondaryButton";
import { copyTeam, deleteTeam, getParticularTeamReceiver } from "../../../api";
import { useShowPopup } from "../../../hooks";
import CustomAlertDialog from "../../../components/CustomAlertDialog";

export default function GroupList({ teamList }) {
  const [position, setPosition] = useState([]);
  const [showSortModal, setShowSortModal] = useState(false);

  const param = useParams();

  const localstorage = getLocalStorage("team_position");

  useEffect(() => {
    if (localstorage !== "") {
      let data = [];
      localstorage.split("/").forEach((item) => {
        data.push(JSON.parse(item));
      });
      //假如localstorage有排序過的team，就將teamList有在裡面的按其排序
      //沒排序過的team就放最上面
      const set = new Set();
      let filterIn = [];
      //有排序過
      data.forEach((data) =>
        teamList.forEach((team) => {
          if (data.team_id === team.team_id && !set.has(data.team_id)) {
            set.add(data.team_id);
            filterIn.push(data);
          }
        })
      );
      //檢查是否有修改名稱
      filterIn.forEach((item) => {
        teamList.forEach((team) => {
          if (item.team_id === team.team_id) {
            item.name = team.name;
          }
        });
      });
      //沒排序過
      const filterOut = teamList.filter((team) =>
        !set.has(team.team_id) ? set.add(team.team_id) : false
      );
      setPosition(filterOut.concat(filterIn));
    } else {
      setPosition(teamList);
    }
  }, [teamList, localstorage]);

  //點擊排序群組及完成排序執行動作
  const handleClick = () => {
    let data = "";
    position.forEach((item, index) => {
      data += JSON.stringify(item) + (index < teamList.length - 1 ? "/" : "");
    });
    setTeamPosition(data);
    setShowSortModal(!showSortModal);
  };

  return (
    <>
      {!param.id ? (
        <div className="relative">
          <div className="flex items-center mb-6">
            <CustomDialog
              className="btn svg-btn !mr-6 !bg-[var(--color-secondary-4)] hover:!bg-[var(--color-secondary-3)]"
              variant="contained"
              buttonTitle={
                <>
                  <i className="ri-add-line"></i>新增群組
                </>
              }
              dialogTitle={"新增群組"}
              inputProps={{
                url: "/api/contact/create/team",
                method: "post",
                props: { name: "" },
              }}
              reload={true}
            />
            <SecondaryButton
              type={"button"}
              icon={<i className="ri-sort-asc"></i>}
              text={!showSortModal ? "排序群組" : "完成"}
              custom={showSortModal && "absolute z-50 left-[174px]"}
              onClick={handleClick}
            />
          </div>
          {showSortModal ? (
            <>
              <div className="w-full h-full bg-black bg-opacity-20 fixed left-0 top-0 z-40"></div>
              <ReactSortable
                tag={"ul"}
                className={
                  "flex flex-col gap-3 " + (showSortModal && "relative z-50")
                }
                list={position}
                setList={setPosition}
              >
                {position.map((item) => (
                  <li id={item.team_id} key={item.team_id}>
                    <GroupItem show={showSortModal} {...item} />
                  </li>
                ))}
              </ReactSortable>
            </>
          ) : (
            <ul
              className={
                "flex flex-col gap-3 " + (showSortModal && "relative z-10")
              }
            >
              {position.map((result) => {
                return (
                  <GroupItem
                    key={result.team_id}
                    show={showSortModal}
                    {...result}
                  />
                );
              })}
            </ul>
          )}
        </div>
      ) : (
        <ReceiverInnerPage teamList={teamList} />
      )}
    </>
  );
}

const GroupItem = ({ show, team_id, name }) => {
  const [length, setLength] = useState();
  const { setFalse, getShowPopup, setIsShow } = useShowPopup();

  const navigate = useNavigate();

  useEffect(() => {
    getParticularTeamReceiver(team_id).then((response) => {
      if (response.success === false) {
        setFalse(true, response.errorMesg);
      } else {
        setLength(response.data.length);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team_id]);

  const handleNavigate = (e) => {
    e.preventDefault();
    if (!show) {
      navigate(`/receiverlist/grouplist/${team_id}`);
    }
  };

  return (
    <>
      <li className="list-shadow list-hover-bg duration-300">
        <Link
          className="flex flex-1 items-center px-6 py-4"
          onClick={handleNavigate}
        >
          <i
            className={`text-[var(--color-neutral-400)] mr-4 ${
              show ? "ri-menu-fill" : "ri-folder-fill"
            }`}
          ></i>
          {name}({length || 0})
        </Link>
        <div className="flex gap-9 px-6 py-4">
          <CustomDialog
            icon={<i className="ri-pencil-line"></i>}
            dialogTitle={"重新命名"}
            inputProps={{
              url: `/api/contact/update/team/${team_id}`,
              method: "patch",
              props: { name: name },
            }}
            reload={true}
          />
          <CustomDialog
            icon={<i className="ri-file-copy-line"></i>}
            copyFuncion={() => copyTeam(team_id, name)}
            dialogTitle={"複製成功"}
            reload={true}
          />
          <CustomDialog
            icon={<i className="ri-delete-bin-7-line"></i>}
            confirmFuncion={() => deleteTeam(team_id)}
            dialogTitle={"確定要刪除嗎？"}
            dialogContent={
              <>
                <p className="text-center">名單將會移出此群組</p>
                <p className="text-center">而僅在此群組的名單將被刪除</p>
              </>
            }
            reload={true}
          />
        </div>
      </li>
      {getShowPopup().show && (
        <CustomAlertDialog
          show={getShowPopup().show}
          message={getShowPopup().message}
          confirmFunction={() => setIsShow(false)}
        />
      )}
    </>
  );
};
